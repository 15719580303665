import React from 'react'
import { Link } from 'react-router-dom';
import Belt from '../img/belt-conveyor-panorama.jpg'
import IconFlex from '../img/icon-flex.svg'
import IconHeight from '../img/icon-height.svg'
import IconWheel from '../img/icon-wheel.svg'
import IconVolume from '../img/icon-volume.svg'
import IconLiquid from '../img/icon-liquid.svg'
import IconSettings from '../img/icon-settings.svg'
import IconPDF from '../img/icon-pdf.svg'
import ContactUs from '../components/ContactUs'
// import ModelBeltConveyor from '../components/ModelBeltConveyor'
import ScrollToTop from '../components/ScrollToTop';



const BeltConveyor = () => {
   return (
      <div className='pt-5 font-body'>
         <ScrollToTop />
         <div>
            <img className='w-full h-full' src={Belt} />
         </div>
         <div className='w-full px-4 pb-10 pt-5'>
            <div className='max-w-[1240px] mx-auto'>
               <ul className='flex flex-wrap max-sm:hidden'>
                  <Link to="/">
                     <li className='text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Home</li>
                  </Link>
                  <span className='pl-2 text-green-700 text-sm'>|</span>
                  <Link to="/Product">
                     <li className='pl-2 text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Catalog Production</li>
                  </Link>
                  <span className='pl-2 text-green-700 text-sm'>|</span>
                  <li className='pl-2 text-green-700 text-sm'>Belt Conveyor</li>
               </ul>
               <div>
                  <h1 className=' text-center text-6xl uppercase font-extrabold py-20 uppercas max-sm:mt-10 max-sm:text-3xl'>Belt Conveyor</h1>
                  <p>The conveyor belt is mainly used in tasks that require strength and durability. It can also especially support heavyweight materials. The conveyor belt can also be used to convey on a flat or steep surface. It can also transport materials on the slope surface as well as a multi-level production line. The conveyor belt system can also transport moist or wet materials. It is also moveable and portable which enables the system to work more efficiently.</p>
               </div>
               <div className='py-10 grid md:grid-cols-2 gap-10'>
                  <div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconFlex} height={24} width={24} />
                        <p>A conveyor belt can transfer raw materials on a flat surface with a width of 70 cm</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconHeight} height={24} width={24} />
                        <p>The height level is adjustable based on usability</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconWheel} height={24} width={24} />
                        <p>The product comprises of rubber tire that easily movable</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconVolume} height={24} width={24} />
                        <p>There is a part to prevent material falling throughout the belt</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconLiquid} height={24} width={24} />
                        <p>It is waterproof with removable components that are easy for cleaning</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconSettings} height={24} width={24} />
                        <p>It comes with a pulley system that is capable of transferring raw materials in large quantity yet it is energy-saving</p>
                     </div>
                  </div>
                  <div>
                     <table className='w-full text-left'>
                        <tbody>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Model</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>C 80</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Efficiency Output</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>Maximum convey material 80 kg/min</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Current control</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>3-phase, 380V, 50Hz</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Motor power</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>2 hp</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Belt Size</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>70 x 800 cm (Width x Length)</td>
                           </tr>
                        </tbody>
                     </table>
                     <p className='text-sm py-5'> <span className='text-green-600' >*</span> The product details depend entirely on the size and specified models</p>
                     <Link to='https://vrenewable.com/static/media/catalog/Vrenewable-Catalog-Web.pdf' className='flex items-center py-5'>
                        <img className='pr-2' src={IconPDF} height={24} />
                        <p className='font-medium text-green-600'>Download Card Product</p>
                     </Link>
                  </div>
               </div>
            </div>

            {/* <div>
               <ModelBeltConveyor />
            </div> */}

         </div>
         <ContactUs />
      </div>
   )
}

export default BeltConveyor