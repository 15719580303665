import React from 'react';
import ImageSlider from '../components/ImageSlider';
import Advantages from '../components/Advantages';
import Cards from '../components/Cards';
import Button from '../components/Button';
import OrganicWasteProblem from '../components/OrganicWasteProblem';
import PhotoComposte from '../components/PhotoComposte';
import WhatWeDo from '../components/WhatWeDo';
import WorkFlow from '../components/WorkFlow';
import VideoBlock from '../components/VideoBlock';
import Statistic from '../components/Statistic';
import ContactUs from '../components/ContactUs';
import PartnerLogo from '../components/PartnerLogo';
import ScrollToTop from '../components/ScrollToTop';

function Home() {
  return (
    <div>
      <ScrollToTop />
      <ImageSlider />
      <Advantages />
      <Cards />
      <Button />
      <OrganicWasteProblem />
      <PhotoComposte />
      <WhatWeDo />
      <WorkFlow />
      <VideoBlock />
      <Statistic />
      <ContactUs />
      <PartnerLogo />
    </div>
  );
}

export default Home;
