import React from 'react'
import workflow from '../img/workflow.jpg'

const WorkFlow = () => {
  return (
    <div className='w-full h-full my-20 max-sm:hidden'>
      <div className='overflow-hidden mx-auto'>
        <img className='mix-blend-multiply' src={workflow} />
      </div>
    </div>
  )
}

export default WorkFlow