import React from 'react'
import Cards from '../components/Cards';
import ImageSlider from '../components/ImageSlider';
import IconPDF from '../img/icon-pdf.svg'
import { Link } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';

const Product = () => {
   return (
      <div>
         <ScrollToTop />
         <ImageSlider />
         <div className='w-full px-4 mt-5'>
            <div className='max-w-[1240px] mx-auto font-body'>
               <ul className='flex'>
                  <Link to="/">
                     <li className='font-body text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Home</li>
                  </Link>
                  <span className='pl-2 text-green-700 font-body text-sm'>|</span>
                  <li className='pl-2 text-green-700 font-body text-sm'>Product</li>
               </ul>
               <div className='flex flex-wrap justify-between items-center mt-10 leading-4'>
                  <h1 className='font-body text-3xl font-bold'>Product</h1>
                  <Link to='https://vrenewable.com/static/media/catalog/Vrenewable-Catalog-Web.pdf' className='flex items-center py-5'>
                     <img className='pr-2' src={IconPDF} height={24} />
                     <p className='font-medium text-green-600'>Download Full Catalog Production</p>
                  </Link>
               </div>

            </div>
         </div>
         <Cards />
      </div>
   )
}
export default Product