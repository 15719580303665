import React from 'react'
import productwaste from '../img/product-waste.png'

const OrganicWasteProblem = () => {
  return (
    <div className='w-full py-25 bg-slate-200 bg-cover bg-center relative overflow-hidden'>
      <img className='w-full h-full object-cover absolute' src={productwaste} />
      <div className='max-w-[1240px] px-4 py-20 mx-auto relative'>
        <h1 className='text-center text-4xl font-extrabold font-body uppercase'>Organic Waste Problem</h1>
        <div className='mx-auto grid md:grid-cols-2 gap-8 pt-10'>
          <p>The organic waste problem stems from incorrect waste management practices, resulting in foul smells and unsightly garbage heaps that harm the environment. These odors not only cause discomfort but also attract pests, posing health risks. Furthermore, improper disposal in landfills leads to methane emissions, exacerbating global warming. To address this issue, we must prioritize sustainable disposal methods like composting and anaerobic digestion.</p>

          <p>These methods reduce odors, health hazards, and greenhouse gas emissions while producing valuable resources, fostering a cleaner, healthier environment, and supporting the circular economy. It is imperative that we act swiftly to implement these sustainable waste management practices, as they not only mitigate the organic waste problem but also contribute to a greener and more sustainable future for our planet.</p>
        </div>
      </div>
    </div>
  )
}

export default OrganicWasteProblem
