import React from 'react'
import { Link } from 'react-router-dom';
import Spr from '../img/spr-panorama.jpg'
import IconRadius from '../img/icon-radius.svg'
import IconWheel from '../img/icon-wheel.svg'
import IconProtect from '../img/icon-protect.svg'
import IconVolume from '../img/icon-volume.svg'
import IconLiquid from '../img/icon-liquid.svg'
import IconFlex from '../img/icon-flex.svg'
import IconPDF from '../img/icon-pdf.svg'
import ContactUs from '../components/ContactUs'
import ScrollToTop from '../components/ScrollToTop';



const SprSeparator = () => {
   return (
      <div className='pt-5 font-body'>
         <ScrollToTop />
         <div>
            <img className='w-full h-full' src={Spr} />
         </div>
         <div className='w-full px-4 pb-10 pt-5'>
            <div className='max-w-[1240px] mx-auto'>
               <ul className='flex flex-wrap'>
                  <Link to="/">
                     <li className='text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Home</li>
                  </Link>
                  <span className='pl-2 text-green-700 text-sm'>|</span>
                  <Link to="/Product">
                     <li className='pl-2 text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Catalog Production</li>
                  </Link>
                  <span className='pl-2 text-green-700 text-sm'>|</span>
                  <li className='pl-2 text-green-700 text-sm'>Spr Separator</li>
               </ul>
               <div>
                  <h1 className=' text-center text-6xl uppercase font-extrabold py-20 uppercas max-md:text-4xl max-md:py-10'>Spr Separator</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu volutpat ac pellentesque nisl aenean eu ut id. Neque porta potenti felis pulvinar augue sed. Sagittis enim, at rhoncus mi odio proin. Suscipit quis volutpat felis, cursus id tellus in ligula tempor. Facilisis ac urna tincidunt id consectetur aenean nunc. Non sed mi, tristique a eget convallis. Malesuada ac et aliquam nisi, venenatis tortor massa iaculis penatibus. Vitae, hendrerit molestie ac mi arcu viverra tellus felis. Arcu nec ac urna parturient risus dapibus nunc, elit nec. Sit malesuada quam mi nec aliquam tristique.</p>
               </div>
               <div className='py-10 grid md:grid-cols-2 gap-10'>
                  <div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconRadius} height={24} width={24} />
                        <p>Sieve hole 20 mm</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconWheel} height={24} width={24} />
                        <p>Rubber wheel and Bracing system</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconProtect} height={24} width={24} />
                        <p>Steel tanks resist corrosion and rust</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconVolume} height={24} width={24} />
                        <p>Receiving Tray Volume 1 cubic</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconLiquid} height={24} width={24} />
                        <p>There is a steel tray to collect raw materials</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconFlex} height={24} width={24} />
                        <p>Belt Conveyor size 80 cm</p>
                     </div>
                  </div>
                  <div>
                     <table className='w-full text-left'>
                        <tbody>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Model</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>SPR 20</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Efficiency Output</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>Separation efficiency 1.5 cubic meters per 1 hr</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Current control</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>3-phase, 380V, 50Hz</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Motor power</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>5 hp</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Machine size</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>300 x 240 cm (Width x Length)</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Rotary tank diameter</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>200 cm</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Receiving tank packing size</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>1 cubic meter</td>
                           </tr>
                        </tbody>
                     </table>
                     <p className='text-sm py-5'> <span className='text-green-600' >*</span> The product details depend entirely on the size and specified models</p>
                     <Link to='https://vrenewable.com/static/media/catalog/Vrenewable-Catalog-Web.pdf' className='flex items-center py-5'>
                        <img className='pr-2' src={IconPDF} height={24} />
                        <p className='font-medium text-green-600'>Download Card Product</p>
                     </Link>
                  </div>
               </div>
            </div>
         </div>
         <ContactUs />
      </div>
   )
}

export default SprSeparator