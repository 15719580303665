import Navbar from './Navbar';
import Footer from './Footer'
import Home from '../pages/Home';
import TheCompany from '../pages/TheCompany';
import Product from '../pages/Product';
import Projects from '../pages/Projects';
import Contact from '../pages/Contact';
import VCOSmart from '../pages/VCOSmart';
import BeltConveyor from '../pages/BeltConveyor';
import EtoMixingChopping from '../pages/EtoMixingChopping';
import SprSeparator from '../pages/SprSeparator';
import VCOPlus from '../pages/VCOPlus';


import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';


const Router = () => {
   const Layout = () => {
      return (
         <div className='min-h-screen flex flex-col '>
            <Navbar />
            <Outlet />
            <Footer />
         </div>
      );
   }

   const BrowserRoutes = () => {
      return (
         <>
            <BrowserRouter>
               <Routes>
                  <Route path="/" element={<Layout />}>
                     <Route path="/" element={<Home />} />
                     <Route path="TheCompany" element={<TheCompany />} />
                     <Route path="Product" element={<Product />} />
                     <Route path="Projects" element={<Projects />} />
                     <Route path="Contact" element={<Contact />} />

                     <Route path="VCOSmart" element={<VCOSmart />} />
                     <Route path="BeltConveyor" element={<BeltConveyor />} />
                     <Route path="EtoMixingChopping" element={<EtoMixingChopping />} />
                     <Route path="SprSeparator" element={<SprSeparator />} />
                     <Route path="VCOPlus" element={<VCOPlus />} />
                  </Route>
               </Routes>
            </BrowserRouter>
         </>
      );
   }

   return (
      <BrowserRoutes />
   )
}

export default Router