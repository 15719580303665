import React from 'react'
import ImageSlider from '../components/ImageSlider';
import { Link } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';

const TheCompany = () => {
   return (
      <div>
         <ScrollToTop />
         <ImageSlider />
         <div className='w-full px-4 mt-5'>
            <div className='max-w-[1240px] mx-auto font-body'>
               <ul className='flex'>
                  <Link to="/">
                     <li className='font-body text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Home</li>
                  </Link>
                  <span className='pl-2 text-green-700 font-body text-sm'>|</span>
                  <li className='pl-2 text-green-700 font-body text-sm'>The Company</li>
               </ul>
               <div>
                  <h1 className='font-body text-3xl font-bold py-10'>The Company</h1>
                  <p>
                     VRenewable Company Limited was established on 25 July 2013 with registered capital of 20000000 baht to operate the main business of importing and distributing agricultural machinery. We are an importer and developer of machines for environmental management. Contribute to driving the agricultural sector in the management of important waste of Thai government agencies.
                  </p>
                  <p className='pt-3 text-justify'>
                     We are importers and develop machines. This leads to the push to use modern technology to manage environmental pollution in the country. The company has both government and private customers. More than 10 projects in the country have been implemented.
                  </p>
                  <p className='pt-3 text-justify'>
                     The company operates environmental management business. by focusing on solving problems at the source in a systematic and sustainable way It is managed within the organization under business ethics, transparency, fairness, accountability and strictly respecting the law. and attaches importance to the development of the quality of life of the society and the communities concerned by taking into account the benefits of all stakeholders in a balanced manner.
                  </p>
                  <h2 className='text-2xl font-bold pt-8 pb-3'>Innovative machinery for the environment</h2>
                  <p className='pt-3 text-justify'>
                     Economic expansion and changes in consumer behavior of the people As a result, the amount of community waste that has been generated has continuously increased the amount of waste generated in Thailand. and there were problems that followed. Whether it is a problem of odor caused by decomposing waste Leachate mixes into the environment. Garbage heaps become a source of disease vectors. These problems need to be addressed in an effective and effective way.
                  </p>
                  <p className='pt-3 text-justify'>
                     If organic waste can be processed into fertilizer, it will help communities to utilize the processed waste to improve soil quality. The processing method uses aerated fermentation method. Will make fertilizer or soil amendment with good properties. Composting organic waste into fertilizer using oxygen today requires a lot of space and labor. It must be closely supervised for 4 to 5 weeks and must be turned over every 5-7 days to allow the decomposition process to complete. Such management controls are therefore suitable for small amounts of organic waste.
                  </p>
                  <p className='pt-3 text-justify'>
                     In order to manage large volumes of organic waste effectively, it is necessary to use effective technology to accelerate this process by the company’s developed Organic Waste Composting Machine or VCO. VRenewable Co., Ltd., which responds to the increasing amount of waste nowadays.
                  </p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default TheCompany