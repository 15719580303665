import React from 'react'
import { Link } from 'react-router-dom';
import VcoSmart from '../img/vco-smart-panorama.jpg'
import IconWeight from '../img/icon-weight.svg'
import IconTime from '../img/icon-time.svg'
import IconDisplay from '../img/icon-display.svg'
import IconBattery from '../img/icon-battery.svg'
import IconWater from '../img/icon-water.svg'
import IconFlex from '../img/icon-flex.svg'
import IconPDF from '../img/icon-pdf.svg'
import ContactUs from '../components/ContactUs'
import ScrollToTop from '../components/ScrollToTop';
import ReactPlayer from 'react-player'



const VCOSmart = () => {
   return (
      <div className='pt-5 font-body'>
         <ScrollToTop />
         <div>
            <img className='w-full h-full' src={VcoSmart} />
         </div>
         <div className='w-full px-4 pb-10 pt-5'>
            <div className='max-w-[1240px] mx-auto'>
               <ul className='flex flex-wrap'>
                  <Link to="/">
                     <li className='text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Home</li>
                  </Link>
                  <span className='pl-2 text-green-700 text-sm'>|</span>
                  <Link to="/Product">
                     <li className='pl-2 text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Catalog Production</li>
                  </Link>
                  <span className='pl-2 text-green-700 text-sm'>|</span>
                  <li className='pl-2 text-green-700 text-sm'>VCO Smart</li>
               </ul>
               <div>
                  <h1 className=' text-center text-6xl uppercase font-extrabold py-20 uppercas max-md:text-4xl max-md:py-10'>VCO Smart</h1>
                  <p>The conveyor belt is mainly used in tasks that require strength and durability. It can also especially support heavyweight materials. The conveyor belt can also be used to convey on a flat or steep surface. It can also transport materials on the slope surface as well as a multi-level production line. The conveyor belt system can also transport moist or wet materials. It is also moveable and portable which enables the system to work more efficiently.</p>
               </div>
               <div className='py-10 grid md:grid-cols-2 gap-10'>
                  <div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconWeight} height={24} width={24} />
                        <p>Capacity 1000 kilogram per day</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconTime} height={24} width={24} />
                        <p>Chopping, mixing and processing organic materials to produce organic fertilizers. Fermentation aeration system</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconDisplay} height={24} width={24} />
                        <p>Automatic computer and sensor</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconBattery} height={24} width={24} />
                        <p>Biodegradable, Efficient, Low energy</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconWater} height={24} width={24} />
                        <p>Odor free / Waste water free</p>
                     </div>
                     <div className='flex py-2 items-start'>
                        <img className='mr-2' src={IconFlex} height={24} width={24} />
                        <p>Flexible, Easy to transport, Low space requirements</p>
                     </div>
                  </div>
                  <div>
                     <table className='w-full text-left'>
                        <tbody>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Model</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>VCO Smart</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Processing Output</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>1000 kilogram per day</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Motor</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>Electric motor</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Process</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>Preserved process using microbial aeration system</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Odor control system</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>BIO Scrubber</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Processing period</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>18-24 hr/period</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Interior Material</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>Metal</td>
                           </tr>
                           <tr>
                              <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Size</td>
                              <td className='border-2 border-slate-200 px-3 py-2 font-body'>2.0 x 3.0 x 2.3m (Width x Length x Height)</td>
                           </tr>
                        </tbody>
                     </table>
                     <p className='text-sm py-5'> <span className='text-green-600' >*</span> The product details depend entirely on the size and specified models</p>
                     <Link to='https://vrenewable.com/static/media/catalog/Vrenewable-Catalog-Web.pdf' className='flex items-center py-5'>
                        <img className='pr-2' src={IconPDF} height={24} />
                        <p className='font-medium text-green-600'>Download Card Product</p>
                     </Link>
                  </div>
               </div>
            </div>
         </div>

         <div className='w-full h-full mx-auto pb-10 px-4 flex justify-center '>
            <ReactPlayer
               className='react-player'
               url='https://youtu.be/Dg8YzJOdYAs?feature=shared'
            // width='100%'
            // height='100%'
            />
         </div>
         <ContactUs />
      </div>
   )
}

export default VCOSmart