import React from 'react'
import Map from '../components/Map'
import ScrollToTop from '../components/ScrollToTop';
import IconFacebook from '../img/icon-facebook.svg'
import IconLetter from '../img/icon-letter.svg'
import IconPhone from '../img/icon-phone.svg'
import IconWeb from '../img/icon-web.svg'
import IconLocation from '../img/icon-location.svg'
import IconPdf from '../img/icon-pdf.svg'
import { Link } from 'react-router-dom';

const Contact = () => {
   return (
      <div className="w-full px-4 mt-10 font-body">
         <div className="max-w-[1240px] mx-auto">
            <ul className='flex'>
               <ScrollToTop />
               <Link to="/">
                  <li className='font-body text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Home</li>
               </Link>
               <span className='pl-2 text-green-700 font-body text-sm'>|</span>
               <li className='pl-2 text-green-700 font-body text-sm'>Contact</li>
            </ul>
            <div>
               <h1 className='font-body text-3xl font-bold py-10'>Contact</h1>
               <Map />
            </div>
            <div className="flex mt-10 justify-between flex-wrap">
               <div>
                  <div className="flex py-1 items-start">
                     <img src={IconFacebook} />
                     <p className="pl-2">VCO Organic Waste to Compost</p>
                  </div>
                  <div className="flex py-1 items-start">
                     <img src={IconLetter} />
                     <p className="pl-2 cursor-pointer" onClick={() => window.location = 'mailto:info.vrenewable@gmail.com'}>info.vrenewable@gmail.com</p>
                  </div>
                  <div className="flex py-1 items-start">
                     <img src={IconPhone} />
                     <a className="pl-2 cursor-pointer" href="tel:+66614644519">+ 66 614 644 519</a>
                  </div>
                  <div className="flex py-1 items-start">
                     <img src={IconWeb} />
                     <p className="pl-2 cursor-pointer">vrenewable.com</p>
                  </div>
               </div>
               <div className="w-[70%] h-[200px] max-sm:w-full">
                  <div className="flex py-1 items-start">
                     <img src={IconLocation} />
                     <p className="pl-2">398 Chiang Mai - Lampang Road, Faham Sub District Muang District, Chiang Mai, Thailand 50000 398 ถ.เชียงใหม่-ลำปาง ต.ฟ้าฮ่าม อ.เมือง จ.เชียงใหม่ 50000</p>
                  </div>
                  <Link className="flex py-10 items-start">
                     <img src={IconPdf} />
                     <p className="pl-2 font-medium text-green-600">Download Company Card</p>
                  </Link>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Contact