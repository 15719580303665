import React from 'react'
import technologies from '../img/technologies.svg'
import mobility from '../img/mobility.svg'
import energysaving from '../img/energysaving.svg'
import ecofriendly from '../img/ecofriendly.svg'

const Advantages = () => {
   return (
      <div className='w-full py-[5rem] px-2 bg-green-600'>
         <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-8 max-md:grid-cols-2'>
            <div className='w-full flex flex-col'>
               <img className='mx-auto hover:scale-105 duration-200 max-md:h-[120px]' src={technologies} height={150} width={150} />
               <h2 className='text-lg font-body text-center py-4 text-white leading-5'>Modern Technologies</h2>
            </div>
            <div className='w-full flex flex-col'>
               <img className='mx-auto hover:scale-105 duration-200 max-md:h-[120px]' src={mobility} height={150} width={150} />
               <h2 className='text-lg font-body text-center py-4 text-white leading-5'>Mobility</h2>
            </div>
            <div className='w-full flex flex-col'>
               <img className='mx-auto hover:scale-105 duration-200 max-md:h-[120px]' src={energysaving} height={150} width={150} />
               <h2 className='text-lg font-body text-center py-4 text-white leading-5'>Energy Saving</h2>
            </div>
            <div className='w-full flex flex-col'>
               <img className='mx-auto hover:scale-105 duration-200 max-md:h-[120px]' src={ecofriendly} height={150} width={150} />
               <h2 className='text-lg font-body text-center py-4 text-white leading-5'>Eco Friendly</h2>
            </div>
         </div>
      </div>
   )
}

export default Advantages