import React, { useState } from 'react'
import village from '../img/village.jpg'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'


const Statistic = () => {
   const [counterOn, setCounterOn] = useState(false);
  return (
<ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
      <div className='w-full bg-cover bg-center relative overflow-hidden'>
         <img className='w-full h-full object-cover absolute' src={village} />

         <div className='max-w-[1240px] py-[10rem] relative mx-auto grid md:grid-cols-3 gap-10'>
            <div className='flex flex-col'>
               <h1 className='text-center text-6xl font-extrabold font-body text-white uppercase pb-5 border-b-2 border-b-green-600'>{counterOn && <CountUp start={0} end={20} duration={2} delay={0} />}</h1>
               <p className='text-center text-white uppercase font-body pt-5'>Years on the market</p>
            </div>
            <div className='flex flex-col'>
               <h1 className='text-center text-6xl font-extrabold font-body text-white uppercase pb-5 border-b-2 border-b-green-600'>{counterOn && <CountUp start={0} end={5000} duration={2} delay={0} />}</h1>
               <p className='text-center text-white uppercase font-body pt-5'>Completed Projects</p>
            </div>
            <div className='flex flex-col'>
               <h1 className='text-center text-6xl font-extrabold font-body text-white uppercase pb-5 border-b-2 border-b-green-600'>{counterOn && <CountUp start={0} end={28000} duration={2} delay={0} />}</h1>
               <p className='text-center text-white uppercase font-body pt-5'>Tons of Recycled Organic Waste</p>
            </div>
         </div>
      </div>
   </ScrollTrigger>
  )
}

export default Statistic