import React from 'react'
import { BsArrowRight } from 'react-icons/bs';
import cardvcosmart from '../img/card-vco-smart.png'
import cardbeltconveyor from '../img/card-belt-conveyor.png'
import cardeto from '../img/card-eto.png'
import cardsprseparator from '../img/card-spr-separator.png'
import cardvcoplus from '../img/card-vco-plus.png'
import { Link } from 'react-router-dom';


const Cards = () => {
   return (
      <div className='w-full pt-[5rem] pb-[2rem] px-4 max-sm:py-[1rem]'>
         {/* Block 1 Column */}
         <div className='max-w-[1240px] mx-auto pb-4'>
            {/* Card 01 VCO Smart */}
            <div className='w-full flex flex-col px-7 py-10 bg-slate-200 hover:bg-green-600 hover:text-white duration-500'>
               <img className='mx-auto' src={cardvcosmart} />
               <h2 className='text-2xl font-body font-extrabold uppercase leading-7 pt-5'>VCO Smart</h2>
               <p className='text-sm font-body pt-2'>Brassus, which is a gift from Çelikel engineers to the world of agriculture,
                  is on the field. Everyone's attention is on it. Discover Brassus, the next
                  generation of feed mixers. You will be stronger and go further with Brassus.
               </p>
               <Link to="../VCOSmart" className='flex pt-10 items-center cursor-pointer'>
                  <p className='text-sm font-body font-bold uppercase pr-2'>More Information</p>
                  <BsArrowRight size={20} />
               </Link>
            </div>
         </div>

         {/* Block 3 Column */}
         <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-4'>
            {/* Card 02 Belt Conveyor */}
            <div className='w-full flex flex-col px-7 py-10 bg-slate-200 hover:bg-green-600 hover:text-white duration-500'>
               <img className='mx-auto' src={cardbeltconveyor} />
               <h2 className='text-2xl font-body font-extrabold uppercase leading-7 pt-5'>Belt Conveyor</h2>
               <p className='text-sm font-body pt-2'>Brassus, which is a gift from Çelikel engineers to the world of agriculture,
                  is on the field. Everyone's attention is on it. Discover Brassus, the next
                  generation of feed mixers. You will be stronger and go further with Brassus.
               </p>
               <Link to="../BeltConveyor" className='flex pt-10 items-center cursor-pointer'>
                  <p className='text-sm font-body font-bold uppercase pr-2'>More Information</p>
                  <BsArrowRight size={20} />
               </Link>
            </div>

            {/* Card 03 ETO Mixing / Chopping */}
            <div className='w-full flex flex-col px-7 py-10 bg-slate-200 hover:bg-green-600 hover:text-white duration-500'>
               <img className='mx-auto' src={cardeto} />
               <h2 className='text-2xl font-body font-extrabold uppercase leading-7 pt-5'>ETO Mixing / Chopping</h2>
               <p className='text-sm font-body pt-2'>Brassus, which is a gift from Çelikel engineers to the world of agriculture,
                  is on the field. Everyone's attention is on it. Discover Brassus, the next
                  generation of feed mixers. You will be stronger and go further with Brassus.
               </p>

               <Link to="../EtoMixingChopping" className='flex pt-10 items-center cursor-pointer'>
                  <p className='text-sm font-body font-bold uppercase pr-2'>More Information</p>
                  <BsArrowRight size={20} />
               </Link>
            </div>

            {/* Card 04 Spr Separator */}
            <div className='w-full flex flex-col px-7 py-10 bg-slate-200 hover:bg-green-600 hover:text-white duration-500'>
               <img className='mx-auto' src={cardsprseparator} />
               <h2 className='text-2xl font-body font-extrabold uppercase leading-7 pt-5'>Spr Separator</h2>
               <p className='text-sm font-body pt-2'>Brassus, which is a gift from Çelikel engineers to the world of agriculture,
                  is on the field. Everyone's attention is on it. Discover Brassus, the next
                  generation of feed mixers. You will be stronger and go further with Brassus.
               </p>
               <Link to="../SprSeparator" className='flex pt-10 items-center cursor-pointer'>
                  <p className='text-sm font-body font-bold uppercase pr-2'>More Information</p>
                  <BsArrowRight size={20} />
               </Link>
            </div>
         </div>

         {/* Block 1 Column */}
         <div className='max-w-[1240px] mx-auto pt-4'>
            {/* Card 05 VCO Plus Choping & Mixing Unit */}
            <div className='w-full flex flex-col px-7 py-10 bg-slate-200 hover:bg-green-600 hover:text-white duration-500'>
               <img className='mx-auto' src={cardvcoplus} />
               <h2 className='text-2xl font-body font-extrabold uppercase leading-7 pt-5'>VCO Plus Choping & Mixing Unit</h2>
               <p className='text-sm font-body pt-2'>Brassus, which is a gift from Çelikel engineers to the world of agriculture,
                  is on the field. Everyone's attention is on it. Discover Brassus, the next
                  generation of feed mixers. You will be stronger and go further with Brassus.
               </p>
               <Link to="../VCOPlus" className='flex pt-10 items-center cursor-pointer'>
                  <p className='text-sm font-body font-bold uppercase pr-2'>More Information</p>
                  <BsArrowRight size={20} />
               </Link>
            </div>
         </div>
      </div>
   )
}

export default Cards