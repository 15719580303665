import React from 'react'
import ReactPlayer from 'react-player'

const VideoBlock = () => {
  return (
   <div className='w-full bg-slate-200'>
      <div className='max-w-[1240px] h-full mx-auto py-10 px-4 flex justify-center '>
      <ReactPlayer
         className='react-player'
         url='https://youtu.be/Dg8YzJOdYAs?feature=shared'
      // width='100%'
      // height='100%'
      />
      </div>
</div>
  )
}
export default VideoBlock