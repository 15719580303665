import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
   const form = useRef();
 
   const sendEmail = (e) => {
     e.preventDefault();
 
     emailjs.sendForm('service_88feja3', 'template_uqsqmm2', form.current, '2uqVSxzlH2HAjiB2N')
       .then((result) => {
           console.log(result.text);
       }, (error) => {
           console.log(error.text);
       });
   };

  return (
   <div className='max-w-[1240px] py-10 mx-auto px-4'>
      <form className='max-w-[735px] mx-auto flex flex-col ' ref={form} onSubmit={sendEmail}>
         <h1 className='text-center uppercase font-body font-bold text-3xl mb-10'>Application form</h1>
         <label className='font-body pb-2'>Name</label>
         <input className='input' type="text" name="user_name" />
         <label className='font-body pb-2'>Email</label>
         <input className='input' type="email" name="user_email" />
         <label className='font-body pb-2'>Message</label>
         <textarea className='textarea' name="message" />
         <input className='btn bg-green-600 border-none text-white mt-5 hover:bg-green-500' type="submit" value="Send messange" />
      </form>
   </div>
 );
};
export default ContactUs