import React from 'react'
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

const Button = () => {
   return (
      <div className='max-w-[1240px] mx-auto pt-[3rem] pb-[5rem]'>
         <Link to="./Product">
            <button className='btn flex items-center mx-auto border-green-600 text-green-600 hover:bg-green-600 hover:text-white'>Catalog
               <BsArrowRight size={20} className='ml-2' />
            </button>
         </Link>
      </div>
   )
}

export default Button