import React, { useState } from 'react'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';

const ImageSlider = () => {

   const slides = [

      {
         url: 'https://vrenewable.com/static/media/vco-smart-panorama.jpg'
      },
      {
         url: 'https://vrenewable.com/static/media/belt-conveyor-panorama.jpg'
      },
      {
         url: 'https://vrenewable.com/static/media/eto-panorama.jpg'
      },
      {
         url: 'https://vrenewable.com/static/media/vco-plus-panorama.jpg',
      },
   ];

   const [currentIndex, setCurrentIndex] = useState(0);

   const prevSlide = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
   };

   const nextSlide = () => {
      const isLastSlide = currentIndex === slides.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
   };

   const goToSlide = (slideIndex) => {
      setCurrentIndex(slideIndex);
   };

   return (
      <div className='w-full h-[600px] m-auto pt-5 max-xl:h-[480px] max-md:h-[192px]'>
         <div
            style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
            className='w-full h-full bg-center bg-cover duration-500'
         ></div>

         {/* Left Arrow */}
         <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
            <BsChevronCompactLeft onClick={prevSlide} size={30} />
         </div>
         {/* Right Arrow */}
         <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
            <BsChevronCompactRight onClick={nextSlide} size={30} />
         </div>
         <div className='flex mt-[-3rem] justify-center py-2'>
            {slides.map((slide, slideIndex) => (
               <div
                  key={slideIndex}
                  onClick={() => goToSlide(slideIndex)}
                  className='text-2xl cursor-pointer'
               >
                  <RxDotFilled color='#f8fafc' />
               </div>
            ))}
         </div>
      </div>
   );
};

export default ImageSlider