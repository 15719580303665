import React from 'react'
import ImageSlider from '../components/ImageSlider';
import { Link } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import ImgProject1 from '../img/project-01.jpg'
import ImgProject2 from '../img/project-01-1.jpg'
import ImgProject3 from '../img/project-02.jpg'
import ImgProject4 from '../img/project-02-1.jpg'
import ImgProject5 from '../img/project-03.jpg'
import ImgProject6 from '../img/project-03-1.jpg'
import ImgProject7 from '../img/project-04.jpg'
import ImgProject8 from '../img/project-04-1.jpg'

const Projects = () => {
   return (
      <div>
         <ScrollToTop />
         <ImageSlider />
         <div className='w-full px-4 mt-5'>
            <div className='max-w-[1240px] mx-auto font-body'>
               <ul className='flex'>
                  <Link to="/">
                     <li className='font-body text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Home</li>
                  </Link>
                  <span className='pl-2 text-green-700 font-body text-sm'>|</span>
                  <li className='pl-2 text-green-700 font-body text-sm'>Projects</li>
               </ul>
               <div className='justify-between items-center mt-10 leading-4'>
                  <h1 className='font-body text-3xl font-bold'>Projects</h1>
                  <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 gap-10 mt-5 max-sm:gap-0'>

                     {/* {Project 1} */}
                     <div className='flex py-5 '>
                        <div>
                           <div className='overflow-hidden mb-3'>
                              <img className='hover:scale-110 hover:rotate-2 duration-500' src={ImgProject1} />
                           </div>
                           <div className='overflow-hidden mb-3'>
                              <img className='hover:scale-110 hover:rotate-2 duration-500' src={ImgProject2} />
                           </div>
                        </div>
                        <div className='w-[75%] pl-4'>
                           <h3 className='uppercase font-bold leading-5 hover:text-green-700'>Biomass fuel production machine procurement project, 3 projects</h3>
                           <div className='py-2'>
                              <h4 className='uppercase font-bold text-sm text-slate-400'>Located</h4>
                              <p className='text-sm pt-1'>Bureau of Natural Resources and Chiang Mai Province Environment Used in Mae Chaem and Chiang Dao districts.</p>
                           </div>
                           <div className='py-2'>
                              <h4 className='uppercase font-bold text-sm text-slate-400'>Machine</h4>
                              <p className='text-sm pt-1'>ETO-12Q 3 units, Conveyor 3 units</p>
                           </div>
                        </div>
                     </div>

                     {/* {Project 2} */}
                     <div className='flex py-5'>
                        <div>
                           <div className='overflow-hidden mb-3'>
                              <img className='hover:scale-110 hover:rotate-2 duration-500' src={ImgProject3} />
                           </div>
                           <div className='overflow-hidden mb-3'>
                              <img className='hover:scale-110 hover:rotate-2 duration-500' src={ImgProject4} />
                           </div>
                        </div>
                        <div className='w-[75%] pl-4'>
                           <h3 className='uppercase font-bold leading-5 hover:text-green-700'>Procurement project for organic material processing machines Air filling system, 1 project</h3>
                           <div className='py-2'>
                              <h4 className='uppercase font-bold text-sm text-slate-400'>Located</h4>
                              <p className='text-sm pt-1'>Phuket Provincial Administrative Organization</p>
                           </div>
                           <div className='py-2'>
                              <h4 className='uppercase font-bold text-sm text-slate-400'>Machine</h4>
                              <p className='text-sm pt-1'>VCO PLUS 1 unit, ETO-12Q 1 unit, Belt Conveyor 3 unit, Separator 1 unit</p>
                           </div>
                        </div>
                     </div>

                     {/* {Project 3} */}
                     <div className='flex py-5'>
                        <div>
                           <div className='overflow-hidden mb-3'>
                              <img className='hover:scale-110 hover:rotate-2 duration-500' src={ImgProject5} />
                           </div>
                           <div className='overflow-hidden mb-3'>
                              <img className='hover:scale-110 hover:rotate-2 duration-500' src={ImgProject6} />
                           </div>
                        </div>
                        <div className='w-[75%] pl-4'>
                           <h3 className='uppercase font-bold leading-5 hover:text-green-700'>Procurement project for organic material processing machines Air filling pump with accessories, 1 project</h3>
                           <div className='py-2'>
                              <h4 className='uppercase font-bold text-sm text-slate-400'>Located</h4>
                              <p className='text-sm pt-1'>Nakhon Nayok Provincial Administrative Organization</p>
                           </div>
                           <div className='py-2'>
                              <h4 className='uppercase font-bold text-sm text-slate-400'>Machine</h4>
                              <p className='text-sm pt-1'>VCO Plus 1 unit</p>
                           </div>
                        </div>
                     </div>

                     {/* {Project 4} */}
                     <div className='flex py-5'>
                        <div>
                           <div className='overflow-hidden mb-3'>
                              <img className='hover:scale-110 hover:rotate-2 duration-500' src={ImgProject7} />
                           </div>
                           <div className='overflow-hidden mb-3'>
                              <img className='hover:scale-110 hover:rotate-2 duration-500' src={ImgProject8} />
                           </div>
                        </div>
                        <div className='w-[75%] pl-4'>
                           <h3 className='uppercase font-bold leading-5 hover:text-green-700'>Project to purchase a number of material mixing machines, 1 project</h3>
                           <div className='py-2'>
                              <h4 className='uppercase font-bold text-sm text-slate-400'>Located</h4>
                              <p className='text-sm pt-1'>Kathu Municipality</p>
                           </div>
                           <div className='py-2'>
                              <h4 className='uppercase font-bold text-sm text-slate-400'>Machine</h4>
                              <p className='text-sm pt-1'>ETO-6Q, 1 unit</p>
                           </div>
                        </div>
                     </div>


                  </div>
               </div>

            </div>
         </div>
      </div>
   )
}

export default Projects