import React from 'react'
import { Link } from 'react-router-dom';
import Eto from '../img/eto-panorama.jpg'
import IconSettings from '../img/icon-settings.svg'
import IconWeight from '../img/icon-weight.svg'
import IconPower from '../img/icon-power.svg'
import IconHydraulic from '../img/icon-hydraulic.svg'
import IconMeter from '../img/icon-meter.svg'
import IconFlex from '../img/icon-flex.svg'
import IconPDF from '../img/icon-pdf.svg'
import ContactUs from '../components/ContactUs'
import ScrollToTop from '../components/ScrollToTop';


const EtoMixingChopping = () => {
  return (
    <div className='pt-5 font-body'>
      <ScrollToTop />
      <div>
        <img className='w-full h-full' src={Eto} />
      </div>
      <div className='w-full px-4 pb-10 pt-5'>
        <div className='max-w-[1240px]  mx-auto'>
          <ul className='flex flex-wrap'>
            <Link to="/">
              <li className='text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Home</li>
            </Link>
            <span className='pl-2 text-green-700 text-sm'>|</span>
            <Link to="/Product">
              <li className='pl-2 text-sm hover:text-green-700 ease-out duration-500 cursor-pointer'>Catalog Production</li>
            </Link>
            <span className='pl-2 text-green-700 text-sm'>|</span>
            <li className='pl-2 text-green-700 text-sm max-sm:pl-0'>ETO Mixing Chopping Machine</li>
          </ul>
          <div>
            <h1 className=' text-center text-6xl uppercase font-extrabold py-20 uppercas max-md:text-4xl max-md:py-10'>ETO Mixing Chopping Machine</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu volutpat ac pellentesque nisl aenean eu ut id. Neque porta potenti felis pulvinar augue sed. Sagittis enim, at rhoncus mi odio proin. Suscipit quis volutpat felis, cursus id tellus in ligula tempor. Facilisis ac urna tincidunt id consectetur aenean nunc. Non sed mi, tristique a eget convallis. Malesuada ac et aliquam nisi, venenatis tortor massa iaculis penatibus. Vitae, hendrerit molestie ac mi arcu viverra tellus felis. Arcu nec ac urna parturient risus dapibus nunc, elit nec. Sit malesuada quam mi nec aliquam tristique.</p>
          </div>
          <div className='py-10 grid md:grid-cols-2 gap-10'>
            <div>
              <div className='flex py-2 items-start'>
                <img className='mr-2' src={IconSettings} height={24} width={24} />
                <p>Mixing/Chopping Multi machine for production fertilizer, animal food and biofuel</p>
              </div>
              <div className='flex py-2 items-start'>
                <img className='mr-2' src={IconWeight} height={24} width={24} />
                <p>Capacity 2.5 tons/batch</p>
              </div>
              <div className='flex py-2 items-start'>
                <img className='mr-2' src={IconPower} height={24} width={24} />
                <p>Powered by P.T.O. or electric motor</p>
              </div>
              <div className='flex py-2 items-start'>
                <img className='mr-2' src={IconHydraulic} height={24} width={24} />
                <p>Control belt conveyer with hydraulic system</p>
              </div>
              <div className='flex py-2 items-start'>
                <img className='mr-2' src={IconMeter} height={24} width={24} />
                <p>Control weight ana raw materials with digital meter</p>
              </div>
              <div className='flex py-2 items-start'>
                <img className='mr-2' src={IconFlex} height={24} width={24} />
                <p>Flexible, easy to transport</p>
              </div>
            </div>
            <div>
              <table className='w-full text-left'>
                <tbody>
                  <tr>
                    <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Model</td>
                    <td className='border-2 border-slate-200 px-3 py-2 font-body'>ETO 6</td>
                  </tr>
                  <tr>
                    <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Volume / Weight</td>
                    <td className='border-2 border-slate-200 px-3 py-2 font-body'>6 cubic / 2.5 ton</td>
                  </tr>
                  <tr>
                    <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Capacity</td>
                    <td className='border-2 border-slate-200 px-3 py-2 font-body'>2.5 ton / batch</td>
                  </tr>
                  <tr>
                    <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Motor power</td>
                    <td className='border-2 border-slate-200 px-3 py-2 font-body'>30 hp</td>
                  </tr>
                  <tr>
                    <td className='border-2 border-slate-200 bg-slate-100 px-3 py-2 uppercase font-bold leading-5'>Machine size</td>
                    <td className='border-2 border-slate-200 px-3 py-2 font-body'>2.1 x 4.7 x 2.4 m (Width x Length x Height)</td>
                  </tr>
                </tbody>
              </table>
              <p className='text-sm py-5'> <span className='text-green-600' >*</span> The product details depend entirely on the size and specified models</p>
              <Link to='https://vrenewable.com/static/media/catalog/Vrenewable-Catalog-Web.pdf' className='flex items-center py-5'>
                <img className='pr-2' src={IconPDF} height={24} />
                <p className='font-medium text-green-600'>Download Card Product</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ContactUs />
    </div>
  )
}

export default EtoMixingChopping