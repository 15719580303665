import React from 'react'
import PartnerLogo_01 from '../img/partner-logo-01.png'
import PartnerLogo_02 from '../img/partner-logo-02.png'
import PartnerLogo_03 from '../img/partner-logo-03.png'
import PartnerLogo_04 from '../img/partner-logo-04.png'
import PartnerLogo_05 from '../img/partner-logo-05.png'

const PartnerLogo = () => {
   return (
      <div className='w-full py-5 px-2 bg-slate-200 mt-20'>
         <div className='max-w-[1240px] mx-auto items-end grid md:grid-cols-5 gap-20 max-md:grid-cols-3 max-md:gap-5'>
            <img className='mix-blend-multiply' src={PartnerLogo_01} />
            <img className='mix-blend-multiply' src={PartnerLogo_02} />
            <img className='mix-blend-multiply' src={PartnerLogo_03} />
            <img className='mix-blend-multiply' src={PartnerLogo_04} />
            <img className='mix-blend-multiply' src={PartnerLogo_05} />
         </div>
      </div>
   )
}
export default PartnerLogo