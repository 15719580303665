import React from 'react'
import waterhyacinth from '../img/water-hyacinth.png'
import vegetables from '../img/vegetables.png'
import food from '../img/food.png'
import trees from '../img/trees.png'

const PhotoComposte = () => {
  return (
    <div className='max-w-[1130px] mx-auto px-4 py-10 grid md:grid-cols-2 gap-5'>
         <div className='relative overflow-hidden' >
            <img className='w-full h-full hover:scale-110 hover:rotate-2 duration-500' src={waterhyacinth} />  
            <p className='absolute text-white font-body bottom-4 left-4'>Water Hyacinth</p>
         </div>

         <div className='relative overflow-hidden'>
            <img className='w-full h-full hover:scale-110 hover:rotate-2 duration-500' src={vegetables} />  
            <p className='absolute text-white font-body bottom-4 left-4'>Vegetables</p>
         </div>

         <div className='relative overflow-hidden'>
            <img className='w-full h-full hover:scale-110 hover:rotate-2 duration-500' src={food} />  
            <p className='absolute text-white font-body bottom-4 left-4'>Food</p>
         </div>

         <div className='relative overflow-hidden'>
            <img className='w-full h-full hover:scale-110 hover:rotate-2 duration-500' src={trees} />  
            <p className='absolute text-white font-body bottom-4 left-4'>Trees</p>
         </div>
         

    </div>
  )
}

export default PhotoComposte