import React from 'react'
import { AiFillYoutube } from 'react-icons/ai'
import { BsFacebook } from 'react-icons/bs'
import { Link } from 'react-router-dom';

function Footer() {
   return (
      <div className='mt-auto pt-[10rem]'>
         <div className='w-full py-10 px-4 mx-auto bg-gray-950'>
            <div className='max-w-[1240px] mx-auto'>
               <div className='flex justify-between flex-wrap max-md:justify-center'>
                  <div className='text-white flex flex-col font-body max-md:text-center'>
                     <a className='text-xl font-bold' href="tel:+66614644519"><span className='text-green-600 pr-2'>+</span>66 614 644 519</a>
                     <p className='text-sm cursor-pointer decoration-none' onClick={() => window.location = 'mailto:info@bettercleanerfuture.com'}>info.vrenewable@gmail.com</p>
                     <p className='text-slate-500 text-sm mt-5'>398 ถ.เชียงใหม่-ลำปาง ต.ฟ้าฮ่าม อ.เมือง จ.เชียงใหม่ 50000</p>
                  </div>
                  <div className='flex flex-col items-end max-md:mt-1 max-md:items-center'>
                     <ul className='text-white font-body text-sm uppercase flex justify-center cursor-pointer max-md:text-xs'>
                        <Link to='../TheCompany' className='pl-4 hover:text-green-700 ease-out duration-500 max-md:pl-0 max-md:p-3'>The Company</Link>
                        <Link to='../Product' className='pl-4 hover:text-green-700 ease-out duration-500 max-md:pl-0 max-md:p-3'>Product</Link>
                        <Link to='../Projects' className='pl-4 hover:text-green-700 ease-out duration-500 max-md:pl-0 max-md:p-3'>Projects</Link>
                        <Link to='../Contact' className='pl-4 hover:text-green-700 ease-out duration-500 max-md:pl-0 max-md:p-3'>Contact</Link>
                     </ul>
                     <div className='flex items-center mt-11 max-md:items-center'>
                        <p className='text-slate-500'>Follow Us</p>
                        <a href="https://www.youtube.com/@nateetheppot930/videos" target="_blank" rel="noreferrer" className='pl-3 cursor-pointer'><AiFillYoutube size={30} color='#334155'
                           onMouseOver={({ target }) => target.style.color = "#64748b"}
                           onMouseOut={({ target }) => target.style.color = "#334155"} /></a>
                        <a href="https://www.facebook.com/Vrenewable" target="_blank" rel="noreferrer" className='pl-3 cursor-pointer'><BsFacebook size={26} color='#334155'
                           onMouseOver={({ target }) => target.style.color = "#64748b"}
                           onMouseOut={({ target }) => target.style.color = "#334155"} /></a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className='w-full py-10 px-4 mx-auto bg-slate-50'>
            <div className='max-w-[1240px] mx-auto flex justify-between flex-wrap font-body text-sm max-md:justify-center'>
               <div>
                  <p className='text-slate-500'>© 2023 Vrenewable Co., Ltd. All Rights Reserved</p>
               </div>
               <ul className='flex'>
                  <li className='pl-2 text-slate-500 hover:text-green-700 ease-out duration-500 cursor-pointer'>Cookies</li>
                  <span className='text-green-600 pl-2'>|</span>
                  <li className='pl-2 text-slate-500 hover:text-green-700 ease-out duration-500 cursor-pointer'>Private Policy</li>
                  <span className='text-green-600 pl-2'>|</span>
                  <li className='pl-2 text-slate-500 hover:text-green-700 ease-out duration-500 cursor-pointer'>Legal note</li>
               </ul>

            </div>
         </div>
      </div>
   )
}

export default Footer