import React from 'react'
import productwaste from '../img/seeds.png'

const WhatWeDo = () => {
   return (
      <div className='w-full py-25 mt-20 bg-slate-200 bg-cover bg-center relative overflow-hidden max-sm:mt-10'>
         <img className='w-full h-full object-cover absolute mix-blend-multiply' src={productwaste} />
         <div className='max-w-[1240px] px-4 py-20 mx-auto relative'>
            <h1 className='text-center text-4xl font-extrabold font-body uppercase'>What We Do</h1>
            <div className='mx-auto grid md:grid-cols-2 gap-8 pt-10'>
               <p>One approach to proper community-based organic agricultural waste management is to convert it into compost or soil conditioner for agricultural use. Currently, there is a technology to speed up the aerated decomposition process with an aerated organic material processing machine (VCO) developed by Vi-Renewable Co., Ltd. This innovative machine provides a practical solution by efficiently breaking down organic waste into nutrient-rich compost.</p>

               <p>By implementing such advanced technologies on a broader scale, communities can not only reduce the foul smells and environmental hazards associated with organic waste but also harness its potential to enhance soil quality and crop productivity. This sustainable approach not only addresses the organic waste problem but also promotes the responsible use of resources in agriculture, contributing to a more resilient and environmentally friendly food production system.</p>
            </div>
         </div>
      </div>
   )
}

export default WhatWeDo